$(document).ready(function () {
	//Declare normal variable javascript
	//Hide element when smaller than 1025
	if ($(window).width() < 1025) {
		$(".main-menu-nav").fadeIn(function () {
			$(".main-menu-nav").css({
				display: "flex",
			});
		});
		$("#fullpage").removeAttr("id");
	}
	//Library init
	if ($("body").hasClass("en-us")) {
		$("#fullpage").fullpage({
			//Navigation
			licenseKey: "A3DA879C-B1254377-8A906973-AAE812EA",
			bigSectionsDestination: top,
			menu: "#menu-parallax",
			lockAnchors: false,
			anchors: [
				"banner",
				"about",
				"product",
				"dealer",
				"award",
				"news",
				"contact",
			],
			navigation: true,
			navigationPosition: "right",
			navigationTooltips: [
				"Banner",
				"About",
				"Product",
				"Dealer",
				"Award",
				"News",
				"Contact",
			],
			showActiveTooltip: false,
			slidesNavigation: true,
			slidesNavPosition: "bottom",
			showActiveTooltip: true,
			//Scrolling
			css3: true,
			scrollingSpeed: 700,
			autoScrolling: true,
			fitToSection: true,
			fitToSectionDelay: 1000,
			scrollBar: false,
			easing: "easeInOutCubic",
			easingcss3: "ease",
			loopBottom: false,
			loopTop: false,
			loopHorizontal: true,
			continuousVertical: false,
			scrollOverflow: false,
			touchSensitivity: 15,
			normalScrollElementTouchThreshold: 5,

			//Accessibility
			keyboardScrolling: true,
			animateAnchor: true,
			recordHistory: true,

			//Design
			controlArrows: true,
			verticalCentered: true,
			resize: false,
			responsiveWidth: 0,
			responsiveHeight: 0,

			//Custom selectors
			sectionSelector: "section",
			dragAndMove: true,
			//events
			afterLoad: function () {
				$(".fp-table.active .aos-init").addClass("aos-animate");
			},
			onLeave: function (origin, destination, direction, trigger) {
				$("video").each(function () {
					$(this).get(0).play();
				});
			},
		});
	} else if ($("body").hasClass("vi-vn")) {
		$("#fullpage").fullpage({
			//Navigation
			licenseKey: "A3DA879C-B1254377-8A906973-AAE812EA",
			bigSectionsDestination: top,
			menu: "#menu-parallax",
			lockAnchors: false,
			anchors: [
				"banner",
				"about",
				"product",
				"dealer",
				"award",
				"news",
				"contact",
			],
			navigation: true,
			navigationPosition: "right",
			navigationTooltips: [
				"Banner ",
				"Về chúng tôi",
				"Sản phẩm",
				"Hệ thống phân phối",
				"Chứng nhận & giải thưởng",
				"Tin tức ưu đãi",
				"Liên hệ",
			],
			showActiveTooltip: false,
			slidesNavigation: true,
			slidesNavPosition: "bottom",
			showActiveTooltip: true,
			//Scrolling
			css3: true,
			scrollingSpeed: 700,
			autoScrolling: true,
			fitToSection: true,
			fitToSectionDelay: 1000,
			scrollBar: false,
			easing: "easeInOutCubic",
			easingcss3: "ease",
			loopBottom: false,
			loopTop: false,
			loopHorizontal: true,
			continuousVertical: false,
			scrollOverflow: false,
			touchSensitivity: 15,
			normalScrollElementTouchThreshold: 5,

			//Accessibility
			keyboardScrolling: true,
			animateAnchor: true,
			recordHistory: true,

			//Design
			controlArrows: true,
			verticalCentered: true,
			resize: false,
			responsiveWidth: 0,
			responsiveHeight: 0,

			//Custom selectors
			sectionSelector: "section",
			dragAndMove: true,
			//events
			afterLoad: function () {
				$(".fp-table.active .aos-init").addClass("aos-animate");
			},
			onLeave: function (origin, destination, direction, trigger) {
				$("video").each(function () {
					$(this).get(0).play();
				});
			},
		});
	}

	// AOS.init({
	// 	// Global settings:
	// 	disable: 'tablet', // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function

	// 	offset: 120, // offset (in px) from the original trigger point
	// 	delay: 0, // values from 0 to 3000, with step 50ms
	// 	duration: 700, // values from 0 to 3000, with step 50ms
	// 	easing: 'ease', // default easing for AOS animations
	// 	once: true, // whether animation should happen only once - while scrolling down
	// 	mirror: false, // whether elements should animate out while scrolling past them
	// 	anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation

	//   });

	$('[data-fancybox="modal"]').fancybox({
		// Options will go here
		modal: true,
		smallBtn: true,
		toolbar: true,
	});
	//Declare function Javascript
	toggleMobileMenu();
	toggleSearch();
	swiperInit();
	// jsSocialShare();
	// toggleApplyForm();
	mappingMenuLeft();
	mappingMenuRight();
	tabActive();
});
function toggleSearch() {
	let button = $(".search-toggle");
	let search = $(".searchbox");
	button.on("click", function () {
		if (search.hasClass("active")) {
			search.removeClass("active");
		} else {
			search.addClass("active");
		}
	});
}

function tabActive() {
	$(".tab-navigation li a").on("click", function () {
		$(this).parents(".tab-navigation").find("li").removeClass("active");
		$(this).parents("li").addClass("active");
		var display = $(this).attr("data-type");
		$(".tab-item").removeClass("active");
		$("#" + display).addClass("active");
	});
}
function toggleMobileMenu() {
	var $hamburger = $(".hamburger");
	$(".main-menu-toggle").on("click", function () {
		$(".mobile-wrapper").toggleClass("active");
		$hamburger.toggleClass("is-active");
	});
}
function swiperInit() {
	var primarySwiper = new Swiper(".primary-banner .swiper", {
		// Optional parameters
		slidesPerView: 1,
		observer: true,
		observeParents: true,
		preventInteractionOnTransition: true,
		speed: 1205,
		autoplay: false,
		lazy: {
			loadPrevNext: true,
		},
		loop: false,
	});

	var partner = new Swiper(".swiper-home-award .swiper", {
		// Optional parameters
		spaceBetween: 0,
		speed: 1205,
		slidesPerView: 1,
		autoplay: {
			delay: 3000,
		},
		navigation: {
			nextEl: ".swiper-home-award .nav-arrow-next",
			prevEl: ".swiper-home-award .nav-arrow-prev",
		},
	});
}

function mappingMenuLeft() {
	return new MappingListener({
		selector: ".main-menu-left",
		mobileWrapper: ".mobile-wrapper",
		mobileMethod: "appendTo",
		desktopWrapper: ".header-wrapper-left",
		desktopMethod: "appendTo",
		breakpoint: 1025,
	}).watch();
}

function mappingMenuRight() {
	return new MappingListener({
		selector: ".main-menu-right",
		mobileWrapper: ".mobile-wrapper",
		mobileMethod: "appendTo",
		desktopWrapper: ".language-wrapper",
		desktopMethod: "insertBefore",
		breakpoint: 1025,
	}).watch();
}
